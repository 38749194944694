footer {
    position: absolute;
    text-align: center;
    bottom: 0;
    width: 100%;
    height: 1.7rem;
  }
  
  footer p {
    color: rgb(134, 133, 133);
  }
:root {
  --primary: rgb(245, 245, 220);
}

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
}
.btn:hover {
  padding: 8px 20px;
  transition: all 0.3s ease-out;
  background: #fff;
  color: #878787;
  transition: 250ms;
}

.btn--primary {
  background-color: var(--primary);
}

.btn--medium {
  padding: 8px 20px;
  border-radius: 4px;
  font-size: 18px;
  color: #878787;
}

.btn--large {
  padding: 12px 26px;
  border-radius: 4px;
  font-size: 20px;
  color: #fff;
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border-radius: 4px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

@import "../../Theme.scss";
/* ----- Home -----  */

.home {
  width: 100%;
  min-height: 1000px;
  background-color: #FDE68A;
  background-image: url(../../assests/home.jpg);

  padding-top: 40px;
  .home__container {
    width: 90%;
    margin: 0px auto;
  
    // background-color: lightgreen;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    &:hover .home__item:not(:hover) {
      filter: grayscale(100%);
  
      &::after {
        background-color: rgba(#000, 0.5);
      }
    }
    .home__item {
      //   background-color: lightcoral;
      width: 350px;
      height: 375px;
      margin: 10px;
      cursor: pointer;
      transition: .3s;

      .home__item__image {
        width: 100%;
        height: 300px;
        // background-color: lightblue;

        img {
          width: 350px;
          height: 300px;
          object-fit: cover;
          object-position: left;
        }
      }
      .home__item__title {
        width: 100%;
        height: 75px;
        // background-color: seagreen;
        background-color: $DarkGrey;

        @include flexbox;

        p {
          color: $white;
          font-size: 24px;
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: "400px") {
  .home {
    .home__container {
      .home__item {
        width: 300px;

        .home__item__image{
            img{
                width: 300px;
            }
        }
      }
    }
  }
}